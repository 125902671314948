<template>
  <img :src="base64" alt="" />
</template>

<script>
import axios from "axios";
export default {
  name: "ImageView",
  data() {
    return {
      base64: "",
      dev: process.env.NODE_ENV === "development",
    };
  },
  props: {
    src: String,
  },
  methods: {
    removeDateTime(base64Data) {
      // 正则表达式匹配 `YYYY-MM-DD HH:mm:ss` 格式
      const dateTimePattern = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/;

      // 使用 replace 方法移除匹配到的日期时间字符串
      return base64Data.replace(dateTimePattern, "");
    },
  },
  async mounted() {
    if (this.dev) {
      return (this.base64 = this.src);
    }
    let newPath = this.src
      .replace(/^\/img\//, "/static/")
      .replace(/\.\w+$/, ".txt");
    const response = await axios.get(newPath);
    this.base64 = this.removeDateTime(response.data);
  },
};
</script>

<style scoped lang="scss"></style>
