<template>
  <RndTag class="home">
    aaaa
    <ImageView src="/img/bgimg.png" />
  </RndTag>
</template>

<script>
// @ is an alias to /src
import ImageView from "@/components/ImageView.vue";

export default {
  name: "HomeView",
  components: {
    ImageView,
  },
};
</script>
