<template>
  <component :is="randomTag">
    <slot></slot>
  </component>
</template>

<script>
export default {
  data() {
    return {
      randomTag: this.getRandomTag(),
    };
  },
  methods: {
    getRandomTag() {
      // 随机从 'div'、'section'、'article' 中选择一个
      const tags = ["div", "section", "article"];
      const randomIndex = Math.floor(Math.random() * tags.length);
      return tags[randomIndex];
    },
  },
};
</script>
